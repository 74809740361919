// This file is loaded both on legacy and new layouts and should be moved into application-next
// when we don't have the old layouts anymore (or we don't need the hybrid layouts with AblyUi on legacy pages.)

import './ably-ui.css';

import sprites from '@ably/ui/core/sprites.svg';
import {
  loadSprites,
  createRemoteDataStore,
  attachStoreToWindow,
  reducerSessionData,
  fetchSessionData,
  queryId,
} from '@ably/ui/core/scripts';

import renderer from 'global/react/renderer';
import Footer from '@ably/ui/core/Footer';
import Meganav from '@ably/ui/core/Meganav';
import CookieMessage from '@ably/ui/core/CookieMessage';
import Flash, { reducerFlashes } from '@ably/ui/core/Flash';

import FeatureFooter from '../components/feature-footer/component';
import Status from '../components/react/status';

import './ably-ui/insights';

const store = createRemoteDataStore({ ...reducerSessionData, ...reducerFlashes });
attachStoreToWindow(store);

document.addEventListener('DOMContentLoaded', () => {
  // Inserts a sprite map for <use> tags
  loadSprites(sprites);

  renderer({
    Meganav,
    Footer,
  });
  const featureFooter = queryId('feature-footer');

  if (featureFooter) {
    FeatureFooter();
  }

  // Init React components
  renderer({ Flash, CookieMessage, Status });

  // Only fetch session data on layouts that use session data
  const sessionDataUrl = document.body.dataset.apiSessionData;
  if (sessionDataUrl) {
    fetchSessionData(store, sessionDataUrl);
  }
});
